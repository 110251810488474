<template>
   
            <v-card class="elevation-12">
              <v-card-text>
    <div class="card-padding pb-0">
      <h3 class="text-h3 font-weight-bolder text-primary text-gradient mb-2">
        Reset Password
      </h3>
      <p class="mb-0 text-body">Enter your new password</p>
    </div>
    <div class="card-padding pb-4">
                <v-form
                  ref="form"
                  lazy-validation>

                       <v-row class = "pb-2 pt-0 mt-4">
                        <v-col class="pb-2 pt-0">
                          <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Password</label>

                                <v-text-field
                                        
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
                            "
                                dense
                                single-line
                                            height="36"

                                ref="password"
                                v-model="password"
                                :rules="[() => !!password || 'This field is required']"
                                :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                                :type="showPassword ? 'text' : 'password'"
                                prepend-icon="fa-lock"
                                placeholder="*********"
                                required
                                @click:append="showPassword = !showPassword"
                            />


                        </v-col>
                     </v-row>
                            <v-row class = "pb-2 pt-0">
                        <v-col class="pb-2 pt-0">
                          <label class="text-xs text-typo font-weight-bolder ms-1"
                          >Confirm Password</label>
           
                                <v-text-field
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            class="
                                font-size-input
                                placeholder-lighter
                                input-style 
                                mt-2
                            "
                               dense
                                single-line
                                            height="36" 

                                ref="confirm_password"
                                v-model="confirm_password"
                                :rules="[() => !!confirm_password  && confirm_password == password || 'Passwords must match']"
                                :append-icon="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                prepend-icon="fa-lock"
                                placeholder="*********"
                                required
                                @click:append="showConfirmPassword = !showConfirmPassword"
                            />


                        </v-col>
                     </v-row>


        
                  </v-form>
                  </div>
              </v-card-text>
              <v-card-actions>
                     <v-btn
                color="#fff"
                class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4
                      placeholder-lighter
                      text-xs
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                "

                @click="resetPassword()"
              >
                <v-icon size="12" class="pr-4">fa fa-sync</v-icon>
                Reset Password
              </v-btn>
              </v-card-actions>
            </v-card>

</template>

<script>


export default {
  data: function () {
    return {
      password: '',
      confirm_password: '',
      showPassword: false,
      showConfirmPassword: false,

    }
  },
 
  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    resetPassword: function () {
      if (this.$refs.form.validate()) {
          this.$store.dispatch('resetPassword', { password: this.password, token: this.$route.query.token  })
            .then( response => {
              this.$store.commit('showMessage',{ content: response.data.msg, color: 'success' })
            })
            .catch(err => {
              this.$store.commit('showMessage',{ content: err.response.data.msg, color: 'warning' })

            })
      }else {
        console.log("INIDE EL")
         this.$store.commit('showMessage',{ content: "Kindly fill in both fields", color: 'warning' })
      }
    },
    
  }
}
</script>
